//Pragya Client App
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router'
import store from './store/store.js'
import { createI18n } from 'vue-i18n';
import messages from './common/messages.json'; 

//console.log(messages);

const i18n = createI18n({
  locale: 'en', // Default locale
  fallbackLocale: 'en', // Fallback locale
  messages: {
    en: messages, // Messages imported from messages.json
  }, // Messages imported from messages.json
});

// Global navigation guard
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    /*
    console.log("token: "+token);
    console.log("to.name: "+to.name);
    console.log("from.name: "+from.name);
    */

    if (!token && to.name !== 'login') {
      next({ name: 'login' }); // Redirect to login page if token is missing and route is not login
    } else {
      next(); // Proceed to the next route
    }
  });

createApp(App).use(router).use(store).use(i18n).mount('#app') // Use the store instance in your app